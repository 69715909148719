<template>
  <v-container>
    <v-card outlined max-width="80%" class="mx-auto pa-1 mb-1 rounded-xl" >
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
        />        
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
        />

        <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <v-tabs
      color="#3a4f3f"
      centered 
      v-model="tab">
      <v-tab v-for="(plc, i) in items" :key="i" class="tab">
        {{ plc.plc_name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-8">
      <v-tab-item
        v-for="(plc_id, p) in items" 
        :key="p"
      >
        <v-card>
          <v-row
          jusify="space-between">
            <v-col>
              <v-treeview
              ref="items"
              :items="plc_id.children"
              item-key="id"
              return-object
              open-all
              >

              <template v-slot:prepend="{ item }" >
                <!-- <span class="structure_plc"> {{ item.plc_name }} </span> -->
                <div class="structure_device">
                  <img :src=controllers[item.type] v-if=controllers[item.type] class="structure_icon mr-1">
                  <span> {{ item.device_name }} </span>
                </div>

              </template>

              </v-treeview>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
            >

            <div class="status_info">
              <div class="status_title"> 
                <img class="status_img" :src="water_level"/>  
                탱크수위 
              </div>
              
              <div class="status_content" v-for="tanklevel in plc_id.status.tanklevel" :key="tanklevel">
                <img class="water_level" :src="level_bottom" v-if="tanklevel === '최소 이하'"/>
                <img class="water_level" :src="level_low" v-if="tanklevel === '낮음'"/>
                <img class="water_level" :src="level_middle" v-if="tanklevel === '중간'"/>
                <img class="water_level" :src="level_top" v-if="tanklevel === '높음'"/>
                <img class="water_level" :src="level_error" v-if="tanklevel === '정보 오류'"/>
                <div> {{ tanklevel }} </div>
              </div>
            </div>

            <div class="status_info">
              <div class="status_title"> 
                <img class="status_img" :src="error"/>  
                비정상 가동
              </div>
              <div class="text-center" v-for="error in plc_id.status.error" :key="error">
                {{ error }}
              </div>
            </div>

            <div class="status_info">
              <div class="status_title"> 
                <img class="status_img" style="height:25px;" :src="activate"/>  
                현재 가동 중 
              </div>
              <div class="text-center" v-for="running in plc_id.status.running" :key="running">
                {{ running }} 
              </div>
            </div>
            
            <div class="status_info">
              <div class="status_title"> 
                <img class="status_img" :src="wash"/>  
                청소 중
              </div>
              <div class="text-center" v-for="cleaning in plc_id.status.cleaning" :key="cleaning">
                {{ cleaning }}
              </div>
            </div>
            </v-col>


          </v-row>
        </v-card>

        
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";


export default{
  name: "DeodorizerTest",

  async created(){
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboCompanies();
    await this.comboFarms();

    let cookie_info = VueCookies.get("DeodorizerDeviceSummary"); // 이전 조회정보를 참조

    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
    } else if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
    }

    this.refreshList();
  },
  // updated(){
  //   this.$refs.items.updateAll(true);
  // },
  data(){
    return{
      controllers: {
      컴프레서: require("@/assets/images/compressor.svg") ,
      탱크: require("@/assets/images/tank.svg"),
      차염발생기: require("@/assets/images/HOCL.svg"),
      인버터펌프: require("@/assets/images/pump.svg"),
      },

      level_bottom: require("@/assets/deodorizer/water_level_bottom.svg"),
      level_low: require("@/assets/deodorizer/water_level_low.svg"),
      level_middle: require("@/assets/deodorizer/water_level_mid.svg"),
      level_top: require("@/assets/deodorizer/water_level_high.svg"),
      level_error : require("@/assets/deodorizer/water_level_error.svg"),


      water_level: require("@/assets/deodorizer/water_level.svg"),
      wash: require("@/assets/deodorizer/wash.svg"),
      error: require("@/assets/deodorizer/error.svg"),
      activate: require("@/assets/deodorizer/activate.svg"),



      company : {},
      companyItems : [],
      farm: {},
      farmItems: [],
      
      items:[],
      tab:null,

      loading: false,
    }
  },
  methods:{
    refreshList(){
      this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
        alert("농장/사업장을 선택하세요.");
        this.$refs.farm.focus();
        return;
      }
      this.loading = true;

      Apis.treeDeodorizerDviceMonitoring({
        farm_cd:  this.farm && this.farm.code,
      } ,(res) => {  // 정상처리
        this.$store.commit("resMessage",res.message);

        this.items = res.data;
        // console.log(res.data);

        this.loading = false;
        let cookie_info = {company: this.company, farm: this.farm};
        VueCookies.set("DeodorizerDeviceSummary" ,cookie_info,"30d");

        // console.log(res.data);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("treeDeodorizerDviceMonitoring 호출 오류",err);
        alert("treeDeodorizerDviceMonitoring 호출 오류" + err);
      });
    },

    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },

    async changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};

      if (this.company && this.company.code) {   
        await this.comboFarms();
        if (this.company) this.farm = this.farmItems[0];
      }
    },        

    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.building = {};
      }
      this.buildingItems = [];
    },

  } // methods end
}
</script>

<style lang="scss" scoped>
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}

.tab{
  font-size:15px;
  font-weight:bold;
  letter-spacing: -0.5px;
  width:250px;
}
.structure_device{
  display:flex;
}

.structure_icon{
  width:25px;
  height:25px;
}

.status_info{
  border-radius: 10px;
  margin-bottom: 10px;
  letter-spacing: -1px;
  background-color: #F6F6F6;
  padding:10px;
}

.status_title{
  font-weight:bold;
  font-size: 18px;
  color:#3a4f3f;
  line-height:35px;
  display: flex;
  height:25px;
  line-height:25px;
}
.status_content{
  text-align: center;
}

.water_level{
  height:20px;
}

.status_img{
  height:20px;
  margin-right:5px;
}

</style>